<template>
  <div class="forum-post">
    <div class="forum-post__header">
      <button @click.prevent="goBack" class="forum-post__return">
        <atom-icon
          class="forum-post__return-icon"
          icon="arrow-left"
        ></atom-icon>
        <span class="forum-post__return-text">Retour</span>
      </button>
    </div>
    <div class="forum-post__wrapper">
      <div class="forum-post__body">
        <template v-if="!loading">
          <molecule-post-card
            v-if="post"
            :showUser="false"
            imageMode="contain"
            :post="post"
            :expand="true"
            class="forum-post__post"
          ></molecule-post-card>
          <molecule-comment-input
            class="forum-post__comment"
            v-if="post?.id"
            :postId="post.id"
          ></molecule-comment-input>
          <molecule-comments-card
            class="forum-post__comments"
            v-if="comments?.length"
            :comments="comments"
            @loadMore="loadComments({ postId: post.id, push: true })"
            @deleteComment="decrementCommentsCount"
            :done="isDone"
          ></molecule-comments-card>
        </template>
        <template v-else>
          <molecule-loading-post-card />
          <molecule-loading-comments-card />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import MoleculeNavigation from "@/components/molecules/MoleculeNavigation.vue";
import MoleculePostCard from "@/components/molecules/cards/MoleculePostCard.vue";
import MoleculeCommentInput from "@/components/molecules/MoleculeCommentInput.vue";
import MoleculeCommentsCard from "@/components/molecules/cards/MoleculeCommentsCard.vue";
import MoleculeLoadingPostCard from "@/components/molecules/cards/MoleculeLoadingPostCard.vue";
import MoleculeLoadingCommentsCard from "@/components/molecules/cards/MoleculeLoadingCommentsCard.vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { computed, onBeforeMount, ref, watch } from "vue";
import { postsMutationTypes } from "@/store/types/mutations";
const { state, dispatch, getters, commit } = useStore();
const isDone = ref(false);
const route = useRoute();
const router = useRouter();
const { postId } = route.params;
const communityName = computed(() => state.communities.currentCommunityName);
/* Get state from store*/
const post = computed(() => getters["posts/getById"](postId));
const comments = computed(() => state.posts.comments?.data);
const loading = computed(() => state.posts.loading);
/* function to get comments (by page)*/
const loadComments = async (payload) => {
  try {
    const isNotDone = await dispatch("posts/getComments", payload);
    isDone.value = !isNotDone;
  } catch (err) {
    const { message } = err.response.data;
    console.log(message);
  }
};
/* get post infos and first load of comments */
const fetchData = async () => {
  try {
    commit(`posts/${postsMutationTypes.SET_LOADING}`, true);
    if (!post.value) {
      await dispatch("posts/getById", postId);
    }
    await loadComments({ postId, push: false });
    commit(`posts/${postsMutationTypes.SET_LOADING}`, false);
  } catch (err) {
    const { message } = err.response.data;
    console.log(message);
  }
}
fetchData();
/* Decrease comments count */
const decrementCommentsCount = () => {
  commit(`posts/${postsMutationTypes.SET_COMMENTS_COUNT}`, {postId, communityName:communityName.value, mode:'dec'});
}

/* Watch for route change to clear comments */
watch(route, (to) => {
  commit('posts/SET_COMMENTS',{comments:null,push:false})
});
/* Function to go back to previous route */
const goBack = () => {
  router.go(-1);
}
</script>

<style lang="scss" scoped>
.forum-post {
  @apply container mx-auto px-4;
  &__header {
    @apply my-4;
  }
  &__return {
    @apply flex items-center gap-1 text-gray-base text-[20px] font-medium;
    &-icon {
      @apply h-6 w-6 fill-[gray];
    }
  }
  &__wrapper {
    @apply w-full max-w-[800px];
  }
  &__comments {
  }
  &__comment {
    @apply mb-4;
  }
}
</style>