<template>
  <div class="create-comment">
    <textarea
      class="create-comment__textarea"
      placeholder="Écrivez votre commentaire ici"
      v-model="comment.content"
      @keyup.enter="createComment()"
    ></textarea>
    <div class="create-comment__icons">
      <atom-icon
        :class="`create-comment__icons-item ${!v$.$invalid ? 'create-comment__icons-item--active':''}`"
        icon="send-plane-2"
        :fill="true"
        @click.prevent="createComment()"
      ></atom-icon>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs } from "vue"
import {useStore} from "vuex";
import { useVuelidate } from '@vuelidate/core';
import {required} from "@vuelidate/validators";
const store = useStore();
  const props = defineProps({
    postId:{
      type:String,
      required:true
    }
  });
  const {postId} = toRefs(props);
  const comment = ref({
    content:'',
    parent: postId.value
  });
  const rules = {
    content: {required}
  }
  const v$ = useVuelidate(rules, comment);
  const createComment = async () => {
    try {
      await store.dispatch('posts/createComment', comment.value);
      comment.value.content = '';
    } catch (err) {
      console.log(err);
    }
  }
</script>

<style lang="scss" scoped>
.create-comment {
  @apply w-full bg-white rounded-md
  border border-[#D1D5DB];
  &__textarea{
    @apply h-12 w-full rounded-t-md border-none
    text-[13px]
    py-2 px-[10px]
    resize-none
    relative focus:border-none focus:ring-0;
    &::placeholder{
      @apply text-[13px] text-gray-400;
    }

  }
  &__icons {
    @apply w-full justify-end flex gap-6 relative
    px-3 py-2 rounded-b-md;
    &::before{
      content:'';
      @apply absolute left-1/2 -translate-x-1/2 
      h-[1px] w-full top-[-4px] box-border bg-[#E5E5E5]
      border-l-[10px] border-r-[15px] border-white
    }
    &-item {
      @apply w-5 h-5 fill-[#858585] cursor-pointer;
      &:last-of-type {
        @apply fill-[#858585] pointer-events-none;
        &.create-comment__icons-item--active{
          @apply fill-[#7DAF8C] pointer-events-auto;
        }
      }
    }
  }
}
</style>