<template>
  <div class="comments">
    <div v-for="(comment, index) in comments" :key="index" class="comments__item-container">
      <span v-if="index !== comments.length - 1" class="comments__line" aria-hidden="true" />
      <div class="comments__item">
        <div class="comments__header">
          <atom-image
            v-if="comment.author.avatar"
            :src="imageUrl(comment.author.avatar)"
            variant="rounded sm"
            class="comments__avatar"
          >
          </atom-image>
          <span v-else class="comments__avatar-placeholder">{{
            comment.author.firstname.slice(0, 1) +
            comment.author.lastname.slice(0, 1)
          }}</span>
          <span
            class="comments__icon"
          >
            <ChatAltIcon class="comments__icon-content" aria-hidden="true" />
          </span>
        </div>
        <div class="comments__body">
          <div>
            <div class="comments__user">
              <a href="" class="comments__user-text">
                {{comment.author.firstname + ' ' + comment.author.lastname}}
            </a>
            </div>
            <p class="comments__date">
              A commenté {{moment(comment.createdAt).fromNow() }}
            </p>
          </div>
          <div class="comments__content">
            <p>
              {{comment.content}}
            </p>
          </div>
        </div>
        <button @click.prevent="deleteComment(comment.id)" v-if="comment.author.id == currentUser.userId || currentUser.role == 'admin'" class="comments__delete">
          <TrashIcon class="comments__icon-content" aria-hidden="true" />
        </button>
      </div>
    </div>
    <button v-if="comments?.length >=5 && !done" @click.prevent="emit('loadMore')" class="comments__load-more">
      <span>Voir plus</span>
      <atom-icon icon="arrow-down" class="comments__load-more-icon"></atom-icon>  
    </button>
  </div>
</template>

<script setup>
import { ChatAltIcon, TrashIcon } from '@heroicons/vue/solid';
import moment from 'moment';
moment.locale('fr');
import { inject, toRefs } from 'vue';
import { useAuth } from '@/composables/auth';
import {useStore} from "vuex";
const {dispatch} = useStore();
const { currentUser } = useAuth();
  const props = defineProps({
    comments:{
      type:Array,
      required:true
    },
    done:{
      type:Boolean,
      default:false
    }
  })
  const {comments, done} = toRefs(props);
  const emit = defineEmits(['loadMore','deleteComment']);
  const imageUrl = inject('imageUrl');
  const deleteComment = async (id) => {
    try {
      await dispatch('posts/deleteComment', id);
      emit('deleteComment');
    } catch (err) {
      console.log(err);
    }
  }
</script>

<style lang="scss" scoped>
  .comments{
    @apply bg-white rounded-md p-2 shadow-sm;
    &__item{
      @apply relative flex items-start space-x-3;
      &-container{
        @apply relative pb-8;
      }
    }
    &__line{
      @apply absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200;
    }
    &__header{
      @apply relative;
    }
    &__body{
      @apply min-w-0 flex-1;
    }
    &__user{
      @apply text-sm;
      &-text{
        @apply font-medium text-gray-900;
      }
    }
    &__avatar{
      @apply h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-4 ring-white;
      &-placeholder{
        @apply h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-4 ring-white
        text-white font-bold text-sm;
      }
    }
    &__date{
      @apply mt-0.5 text-sm text-gray-500;
    }
    &__content{
      @apply mt-2 text-sm text-gray-700;
    }
    &__icon{
      @apply absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px;
      &-content{
        @apply h-3 w-3 text-gray-400;
      }
    }
    &__load-more{
      @apply text-gray-base font-medium ml-2 text-sm flex items-center gap-[4px] mb-1 mr-1 ml-auto;
      &-icon{
        @apply w-3 h-3 fill-[gray];
      }
    }
  }
</style>